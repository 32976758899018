import React from "react"
import { FluidObject } from "gatsby-image"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Search from "@material-ui/icons/Search"
import BackgroundImage from "gatsby-background-image"

type BaseProps = { children?: React.ReactNode; className?: string }

const Title: React.FC<BaseProps> = props => (
  <div className={props.className}>{props.children}</div>
)

const SiteName: React.FC<BaseProps> = props => (
  <div className={props.className}>{props.children}</div>
)

const SearchBox: React.FC<BaseProps> = props => (
  <div className={props.className}>
    <form action="https://www.google.com/search">
      <input type="hidden" name="hl" value="ja" />
      <input type="hidden" name="ie" value="utf-8" />
      <input type="hidden" name="oe" value="utf-8" />
      <input type="hidden" name="as_sitesearch" value="knowledge.nilay.jp" />
      <input
        className="queryText"
        type="text"
        name="q"
        placeholder="サイト内検索"
        aria-label="サイト内検索"
      />
      <button className="submit" type="submit" name="btnG" aria-label="検索する">
        <Search />
      </button>
    </form>
  </div>
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      width: "100%",
      minHeight: "300px",
      backgroundSize: "cover",
      backgroundPosition: "50%",
    },
    title: {
      paddingTop: theme.spacing(4),
      textShadow: "1px 1px 0 #fff, -1px 1px 0 #fff",
      textAlign: "center",
      fontSize: "14px",
    },
    siteName: {
      paddingTop: theme.spacing(1),
      textShadow: "1px 1px 0 #fff, -1px 1px 0 #fff",
      textAlign: "center",
      fontSize: "28px",
      fontWeight: "bold",
      fontVariant: "small-caps",
    },
    searchBox: {
      maxWidth: "400px",
      margin: "auto",
      marginTop: theme.spacing(2),
      position: "relative",
      "& .queryText": {
        padding: 0,
        border: 0,
        outline: 0,
        display: "block",
        width: "100%",
        backgroundColor: "hsla(0,0%,100%,.6)",
        color: "#333",
        textAlign: "center",
        lineHeight: "36px",
        borderRadius: "18px",
        "&::active": { backgroundColor: "#fff" },
        "&::placeholder": { color: "#666" },
      },
      "& .submit": {
        padding: 0,
        border: 0,
        outline: 0,
        display: "block",
        backgroundColor: "transparent",
        cursor: "pointer",
        position: "absolute",
        height: "36px",
        width: "36px",
        right: 0,
        top: 0,
      },
    },
  })
)

type Props = {
  siteName: string
  image: FluidObject
}

const Component: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  return (
    <BackgroundImage
      className={classes.background}
      Tag="div"
      fluid={props.image}
      backgroundColor={`#040e18`}
    >
      <Title className={classes.title}>銃砲・射撃・狩猟の情報サイト</Title>
      <SiteName className={classes.siteName}>{props.siteName}</SiteName>
      <SearchBox className={classes.searchBox} />
    </BackgroundImage>
  )
}

export default Component
