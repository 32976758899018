import React from "react"
import { FluidObject } from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { ViewsHomeQuery } from "../../../types/graphql-types"
import SearchBanner from "./search-banner"
import MainCard from "./main-card"
import SubCard from "./sub-card"

type Props = {}

const Component: React.FC<Props> = (props: Props) => {
  const data = useStaticQuery<ViewsHomeQuery>(
    graphql`
      query ViewsHome {
        bannerImage: file(
          relativePath: { eq: "63141dde-f6ee-490c-b283-69b468b80813.jpg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gunImage: file(
          relativePath: { eq: "87c2ff6b-8370-4d37-a1c6-422f2fac3ed0.jpg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 370) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        huntingImage: file(
          relativePath: { eq: "574b2804-3546-4dca-bd9c-a1f48406fd87.jpg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 370) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        shootingImage: file(
          relativePath: { eq: "343de306-e838-4c10-9e27-d89d05c8c8da.jpg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 370) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        feature1Image: file(
          relativePath: { eq: "95caff24-d107-48a1-8989-23134cf60ec9.jpg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 370) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        feature2Image: file(
          relativePath: { eq: "8e2ba4c1-9366-4f91-91ca-d7b96b912a16.jpg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 370) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        feature3Image: file(
          relativePath: { eq: "308e5010-683e-498b-9669-07e9808bdb1f.jpg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          childImageSharp {
            fluid(maxWidth: 370) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <React.Fragment>
      <SearchBanner
        siteName="Nilay/Knowledge"
        image={data.bannerImage.childImageSharp.fluid}
      />

      <Container maxWidth="md">
        <Box mt={4}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={4}>
              <MainCard
                title="銃を手に入れる"
                description="国内で所持許可（免許）を取得し、銃を入手する方法を紹介します。"
                image={data.gunImage.childImageSharp.fluid}
                slug="articles/1378038316"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MainCard
                title="狩猟を始める"
                description="銃・わな・網の狩猟免許の取得方法や免許の種類について紹介します。"
                image={data.huntingImage.childImageSharp.fluid}
                slug="articles/1403944258"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MainCard
                title="射撃を始める"
                description="クレー射撃、空気銃・ライフル射撃の始め方を紹介します。"
                image={data.shootingImage.childImageSharp.fluid}
                slug="articles/1403944258"
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={6} sm={6} md={3}>
              <SubCard
                title="申請書類"
                image={data.feature1Image.childImageSharp.fluid}
                slug="articles/1597956932"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <SubCard
                title="記事一覧"
                image={data.feature2Image.childImageSharp.fluid}
                slug="articles"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <SubCard
                title="狩猟鳥獣図鑑"
                image={data.feature3Image.childImageSharp.fluid}
                slug="articles/1403693668"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export const Home = Component
