import React from "react"
import { Link } from "gatsby"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

type Service = { gunman: string; ecommerce: string; about: string }

type Props = { service: Service }
const Component: React.FC<Props> = (props: Props) => {
  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <Card variant="outlined">
          <CardHeader title="このサイトについて" />
          <CardContent>
            <p style={{ lineHeight: 1.9 }}>
              Nilay/Knowledge
              は銃・射撃・狩猟に関する情報を蓄積し体系的にまとめることを目的としています。
            </p>
          </CardContent>
        </Card>
      </Box>
      <Box mt={4}>
        <Card variant="outlined">
          <CardHeader title="コンテンツ" />
          <List>
            <ListItem component={Link} to="/articles">
              <ListItemText
                primary="Articles"
                secondary="銃・射撃・狩猟に関する情報をまとめています。"
              />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
            <ListItem component={Link} to="/">
              <ListItemText
                primary="News"
                secondary="銃・射撃・狩猟の事故・事件、法令に関するニュースをまとめています。"
              />
            </ListItem>
          </List>
        </Card>
      </Box>
      <Box mt={4}>
        <Card variant="outlined">
          <CardHeader title="その他サービス" />
          <List>
            <ListItem
              component={"a"}
              href={props.service.ecommerce}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText
                primary="通信販売"
                secondary="射撃・狩猟・有害鳥獣駆除に関する商品を取り扱っています。購入にあたり許可が必要な商品の取り扱いはしておりません。"
              />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
            <ListItem
              component={"a"}
              href={props.service.gunman}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText
                primary="Nilay/Gunman"
                secondary="申請・申込書類の作成や使用実績の管理を行うためのアプリです。"
              />
            </ListItem>
          </List>
        </Card>
      </Box>
      <Box mt={4}>
        <Card variant="outlined">
          <CardHeader title="SNS の運用について" />
          <CardContent>
            <p style={{ lineHeight: 1.9 }}>
              毎日12:00と20:00に Twitter
              で銃・射撃・狩猟に関するニュースを配信しています。配信するニュースの基準は、ニュースやプレスリリースの場合有料会員登録せずに読める文章が十分にあり、以下のいずれかを満たすニュースです。
            </p>

            <ul
              style={{
                lineHeight: 1.9,
                listStyleType: "disc",
                margin: "1rem 0 1rem 1.5rem",
                paddingLeft: "1.5rem",
              }}
            >
              <li>
                銃・射撃・狩猟・有害鳥獣駆除に関連したパブリックコメント情報、官公庁の発表、通達
              </li>
              <li>
                射撃スポーツやその普及に関するニュースおよびプレスリリース
              </li>
              <li>狩猟や有害鳥獣駆除に関するニュースおよびプレスリリース</li>
              <li>
                狩猟鳥獣による鳥獣被害とその対策に関するニュースおよびプレスリリース
              </li>
              <li>ジビエに関するニュースおよびプレスリリース</li>
            </ul>

            <p style={{ lineHeight: 1.9 }}>
              また、コラムや論評は署名がある場合のみ配信しますが、特定の分野では扇動的なニュースが配信される場合がありますのでご了承ください。
            </p>
          </CardContent>
        </Card>
      </Box>
      <Box mt={4}>
        <Card variant="outlined">
          <CardHeader title="お問い合わせ" />
          <CardContent>
            <p style={{ lineHeight: 1.9 }}>
              <a
                href={`${props.service.about}/contact`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Nilay/About
              </a>{" "}
              よりお問い合わせください。
            </p>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}

export const About = Component
