import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt"

type BaseProps = { children?: React.ReactNode; className?: string }

const Paper: React.FC<BaseProps> = props => (
  <div className={props.className}>{props.children}</div>
)

const Title: React.FC<BaseProps> = props => (
  <div className={props.className}>{props.children}</div>
)

const Description: React.FC<BaseProps> = props => (
  <div className={props.className}>{props.children}</div>
)

const Action: React.FC<BaseProps> = props => (
  <div className={props.className}>
    <div>{props.children}</div>
    <ArrowRightAlt />
  </div>
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "relative",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      backgroundColor: "#fff",
      width: "100%;",
      height: "100%",
    },
    image: { borderRadius: "4px 4px 0 0" },
    imageWrap: { position: "relative" },
    title: {
      color: "#fff",
      padding: theme.spacing(2),
      position: "absolute",
      bottom: 0,
      left: 0,
      maxWidth: "100%",
      textShadow:
        "1px 1px 0 #666, -1px -1px 0 #666, -1px 1px 0 #666, 1px -1px 0 #666, 0 1px 0 #666, -1px 0 #666, -1px 0 0 #666, 1px 0 0 #666",
      fontSize: "19px",
    },
    description: {
      padding: theme.spacing(2),
      lineHeight: 1.7,
    },
    action: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(2),
      color: "#546e7a",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    link: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  })
)

type Props = {
  title: string
  image: FluidObject
  description: string
  slug: string
}

const Component: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <div className={classes.imageWrap}>
        <Img
          fluid={props.image}
          alt={`${props.title}の画像`}
          className={classes.image}
        />
        <Title className={classes.title}>{props.title}</Title>
      </div>
      <Description className={classes.description}>
        {props.description}
      </Description>
      <Action className={classes.action}>記事を見る</Action>
      <Link
        className={classes.link}
        to={props.slug}
        aria-label="{props.title}へ移動"
      ></Link>
    </Paper>
  )
}

const StyledComponent = styled(Component)``

export default StyledComponent
