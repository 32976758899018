import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

type BaseProps = { children?: React.ReactNode; className?: string }

const Paper: React.FC<BaseProps & { to: string }> = props => (
  <Link to={props.to} className={props.className}>
    {props.children}
  </Link>
)
const Title: React.FC<BaseProps> = props => (
  <span className={props.className}>{props.children}</span>
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "block",
      position: "relative",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      backgroundColor: "#fff",
      width: "100%;",
      height: "100%",
    },
    image: { borderRadius: "4px 4px 0 0" },
    title: {
      display: "block",
      color: "#fff",
      padding: theme.spacing(2),
      position: "absolute",
      bottom: 0,
      left: 0,
      maxWidth: "100%",
      textShadow:
        "1px 1px 0 #666, -1px -1px 0 #666, -1px 1px 0 #666, 1px -1px 0 #666, 0 1px 0 #666, -1px 0 #666, -1px 0 0 #666, 1px 0 0 #666",
      fontSize: "1rem",
    },
  })
)

type Props = {
  title: string
  image: FluidObject
  slug: string
}

const Component: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <Paper to={props.slug} className={classes.paper}>
      <Img
        fluid={props.image}
        alt={`${props.title}の画像`}
        className={classes.image}
      />
      <Title className={classes.title}>{props.title}</Title>
    </Paper>
  )
}

const StyledComponent = styled(Component)``

export default StyledComponent
